import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useLanguage } from 'hooks/use-languages/useLanguages';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import GraduationHatIcon from 'assets/component-icons/GraduationHatIcon';
import { useIntl } from 'react-intl';
import {
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables,
  GetGouvSchoolsQuery,
  GetGouvSchoolsQueryVariables,
  GradeEnum,
  JobTitleKeyEnum,
  PrimarySituationEnum,
  SearchSchoolSubjectsQuery,
  SearchSchoolSubjectsQueryVariables,
  SecondarySituation,
  SecondarySituationEnum,
  AvailableLanguagesEnum,
} from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import SelectInput from '../../../components/select-input/SelectInput';
import { schoolCoachSituationPanelValidationSchema } from './panels.validation';
import { ProfessorSituationTranslations } from './panels.translations';
import { CommonTranslations } from 'i18n/common.translations';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_DEPARTMENTS_QUERY, GET_GOUV_SCHOOLS } from 'gqls/Schools.gql';
import DynamicAutocompleteSelectInput from 'components/dynamic-autocomplete-select-input/DynamicAutocompleteSelectInput';
import DynamicAutocompleteCategorizedSelectInput from 'components/dynamic-autocomplete-categorized-select-input/DynamicAutocompleteCategorizedSelectInput';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import useDebounce from 'hooks/use-debounce';
import StaticAutocompleteMultiSelectInput from 'components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import AutocompleteAddableMultiSelectInput from 'components/autocomplete-addable-multi-select-input/AutocompleteAddableMultiSelectInput';
import RemovableTag from 'components/removable-tag/RemovableTag';
import './SchoolCoachSituationPanel.scss';
import { GradeEnumTranslations } from 'i18n/grade.translations';
import { BinaryResponse } from 'models/BinaryResponse.enum';
import RadioInput from 'components/radio-input/RadioInput';
import { BinaryResponseTranslations } from 'i18n/binary-response.translations';
import { SEARCH_SCHOOL_SUBJECTS_QUERY } from 'gqls/SchoolSubject.gql';
import TextInput from '../../../components/text-input/TextInput';
import formikError from 'components/formik-helper/formik.helper';
import { GouvSchoolCreateModalTranslations } from '../components/gouv-school-create-modal/GouvSchoolCreateModal.translations';
import GouvSchoolCreateModal from '../components/gouv-school-create-modal/GouvSchoolCreateModal';
import useModal from 'components/modal/Modal.hook';

type FormikValues = {
  secondarySituation?: SecondarySituationEnum;
  currentSchoolDepartment: string;
  currentSchoolName: string;
  currentSchoolCity: string;
  position: string;
  teachedSchoolSubjects: string[];
  accompaniedStudyLevels: GradeEnum[];
  headTeacher?: BinaryResponse;
};

export default function SchoolCoachSituationPanel() {
  const intl = useIntl();
  const language = useLanguage();
  const { formatMessage } = intl;

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const settingsContext = useContext(SettingsContext);

  const [availableGrades, setAvailableGrades] = useState<GradeEnum[]>(
    user?.availableGrades || [],
  );

  const [departementSearchValue, setDepartementSearchValue] = useState('');
  const [schoolSearchValue, setSchoolSearchValue] = useState('');
  const [debouncedSchoolSearchValue, debouncedLoading] =
    useDebounce(schoolSearchValue);
  const [schoolSubjectValues, setSchoolSubjectValues] = useState('');
  const [debouncedSchoolSubjectValue, debouncedSchoolSubjectLoading] =
    useDebounce(schoolSubjectValues);

  const networkState = useFormikNetworkState(ArrowRightIcon);

  const [customJobTitle, setCustomJobTitle] = useState<string | null>(null);
  const [openModal, closeModal] = useModal();

  const formik = useFormik<FormikValues>({
    initialValues: {
      secondarySituation: user?.secondarySituation?.key,
      currentSchoolDepartment: '',
      currentSchoolName: '',
      currentSchoolCity: '',
      position: '',
      teachedSchoolSubjects: [],
      accompaniedStudyLevels: [],
      headTeacher: BinaryResponse.No,
    },
    validationSchema: schoolCoachSituationPanelValidationSchema(
      intl,
      user?.primarySituation?.key,
    ),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      const { headTeacher, ...rest } = values;
      const response = await updateUser({
        ...rest,
        headTeacher: headTeacher === BinaryResponse.Yes,
      });
      networkState.succeeded();
    },
  });

  const situations: SecondarySituation[] = useMemo(() => {
    if (!settingsContext?.settings) return [];

    const primarySituation = settingsContext.settings.primarySituations.find(
      ({ key }) => key === user?.primarySituation?.key,
    );
    if (user?.isCoach) {
      return primarySituation!.secondarySituations!.filter(
        (situation) => situation.key !== SecondarySituationEnum.College,
      );
    }
    return primarySituation!.secondarySituations || [];
  }, [
    settingsContext?.settings?.primarySituations,
    user?.primarySituation?.key,
  ]);

  const grades: GradeEnum[] = useMemo(() => {
    const secondarySituation = situations.find(
      ({ key }) => key === formik.values.secondarySituation,
    );

    if (!secondarySituation) return [];

    const isLyceeSituation = [
      SecondarySituationEnum.LyceeGt,
      SecondarySituationEnum.LyceeAgr,
      SecondarySituationEnum.LyceePro,
      SecondarySituationEnum.Cfa,
    ].includes(secondarySituation.key);

    const isPostBacSupport =
      user?.primarySituation?.key ===
      PrimarySituationEnum.PostBacStudentSupport;
    if (isLyceeSituation) {
      return isPostBacSupport
        ? [
            GradeEnum.Bts1,
            GradeEnum.Bts2,
            GradeEnum.Cpge1,
            GradeEnum.Cpge2,
            GradeEnum.Dcg1,
            GradeEnum.Dcg2,
            GradeEnum.Dcg3,
          ]
        : availableGrades.filter((grade) =>
            [
              GradeEnum.Cap1,
              GradeEnum.Cap2,
              GradeEnum.Lycee1,
              GradeEnum.Lycee2,
              GradeEnum.LyceeT,
              GradeEnum.Cfa1,
              GradeEnum.Cfa2,
              GradeEnum.Cfat,
            ].includes(grade),
          );
    }

    return secondarySituation.grades || [];
  }, [
    formik.values.secondarySituation,
    user?.primarySituation?.key,
    situations,
  ]);

  const { data: departmentsData } = useQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GET_DEPARTMENTS_QUERY, {
    variables: {
      search: departementSearchValue,
    },
  });

  const isForeignDepartment = !/\d/.test(formik.values.currentSchoolDepartment);
  const shouldShowSchoolEmptyStateMessageForeignDepartment = isForeignDepartment && debouncedSchoolSearchValue.length >= 1;
  const shouldShowSchoolEmptyStateMessage = shouldShowSchoolEmptyStateMessageForeignDepartment || debouncedSchoolSearchValue.length >= 5;

  const openCreateSchoolModal = () => {
    openModal({
      title: formatMessage(GouvSchoolCreateModalTranslations.title),
      content: (
        <GouvSchoolCreateModal
          onClose={closeModal}
          onSuccess={handleCreateSchool}
          establishmentType={formik.values.secondarySituation}
          departmentLabel={formik.values.currentSchoolDepartment}
          userId={user?.id}
        />
      ),
    });
  };

  const [loadGouvSchools, { data: schoolData, loading: schoolLoading }] =
    useLazyQuery<GetGouvSchoolsQuery, GetGouvSchoolsQueryVariables>(
      GET_GOUV_SCHOOLS,
    );

  const [
    loadSchoolSubjects,
    { data: schoolSubjectData, loading: schoolSubjectLoading },
  ] = useLazyQuery<
    SearchSchoolSubjectsQuery,
    SearchSchoolSubjectsQueryVariables
  >(SEARCH_SCHOOL_SUBJECTS_QUERY);

  useEffect(() => {
    if (debouncedSchoolSearchValue.length > 0) {
      loadGouvSchools({
        variables: {
          department: formik.values.currentSchoolDepartment,
          establishmentType: formik.values.secondarySituation,
          term: debouncedSchoolSearchValue,
        },
      });
    }
  }, [formik.values.currentSchoolDepartment, debouncedSchoolSearchValue]);

  const locale = useMemo(() => {
    return language.language === 'fr'
      ? AvailableLanguagesEnum.Fr
      : AvailableLanguagesEnum.En;
  }, [language.language]);
  
  useEffect(() => {
    if (debouncedSchoolSubjectValue.length > 0) {
      loadSchoolSubjects({
        variables: {
          term: debouncedSchoolSubjectValue,
          locale,
        },
      });
    }
  }, [debouncedSchoolSubjectValue, locale]);
  
  const onSecondarySituationChange = async (
    secondarySituation: SecondarySituationEnum,
  ) => {
    const selectedSecondarySituation = situations.find(
      (situation) => situation.key === secondarySituation,
    );
    setAvailableGrades(selectedSecondarySituation?.grades || []);
    formik.setFieldValue('secondarySituation', secondarySituation);
    formik.setFieldValue('accompaniedStudyLevels', []);
    formik.setFieldValue('teachedSchoolSubjects', []);
    formik.setFieldValue('position', '');
    formik.setFieldValue('currentSchoolDepartment', '');
  };

  const onDepartmentChange = (department: string) => {
    formik.setFieldValue('currentSchoolDepartment', department);
    formik.setFieldValue('currentSchoolName', '');
  };  

  const addTeachedSchoolSubjects = useCallback(
    (selectedSchoolSubects: string[]) => {
      formik.setFieldValue('teachedSchoolSubjects', selectedSchoolSubects);
    },
    [formik.values.teachedSchoolSubjects],
  );

  const addStudyLevelSupported = useCallback(
    (studyLevel: GradeEnum) => {
      if (!formik.values.accompaniedStudyLevels.includes(studyLevel)) {
        formik.setFieldValue('accompaniedStudyLevels', [
          ...formik.values.accompaniedStudyLevels,
          studyLevel,
        ]);
      }
    },
    [formik.values.accompaniedStudyLevels],
  );

  const formatError = (
    touched: any,
    errors: any,
    fieldName: string,
  ): string | undefined => {
    const error = formikError(touched, errors, fieldName);
    if (typeof error === 'string') {
      return error;
    } else if (error && typeof error === 'object') {
      return JSON.stringify(error);
    }
  };

  const handleCreateSchool = (newSchool: {
    name: string;
    communeName: string;
  }) => {
    formik.setFieldValue('currentSchoolName', newSchool.name);
    formik.setFieldValue('currentSchoolCity', newSchool.communeName);
    formik.setSubmitting(false);
    closeModal();
  };

  const getJobTitleNameByKey = (key: string) => {
    const jobTitle = settingsContext?.settings?.jobTitles?.find(
      ({ key: jobKey }) => jobKey === key,
    );
    return jobTitle ? jobTitle.name : '';
  };

  useEffect(() => {
    setAvailableGrades(grades);
  }, [grades]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectInput
        dataCy="secondary-situation"
        name="secondarySituation"
        label={intl.formatMessage(
          ProfessorSituationTranslations.secondarySituation,
        )}
        icon={GraduationHatIcon}
        initialValue={
          user?.secondarySituation && {
            value: user.secondarySituation.key,
            translation: user.secondarySituation.name,
          }
        }
        values={situations.map(({ key, name }) => ({
          value: key,
          translation: name,
        }))}
        onChange={onSecondarySituationChange}
        error={formatError(formik.touched, formik.errors, 'secondarySituation')}
      />

      <DynamicAutocompleteSelectInput
        dataCy="current-school-department"
        name="currentSchoolDepartment"
        label={formatMessage(ProfessorSituationTranslations.departmentLabel)}
        values={(departmentsData?.departments || []).map((department) => ({
          value: department,
          translation: department,
        }))}
        onSelection={onDepartmentChange}
        onTyping={setDepartementSearchValue}
        error={formatError(
          formik.touched,
          formik.errors,
          'currentSchoolDepartment',
        )}
      />

      <DynamicAutocompleteCategorizedSelectInput
        dataCy="current-school-name"
        name="currentSchoolName"
        label={formatMessage(
          ProfessorSituationTranslations.currentSchoolNameLabel,
        )}
        placeholder={intl.formatMessage(
          ProfessorSituationTranslations.currentSchoolNamePlaceholder,
        )}
        value={formik.values.currentSchoolName}
        values={(schoolData?.gouvSchoolSearch || []).map((value) => ({
          value: value,
          categoryKey: value.communeName!,
          translation: value.name!,
        }))}
        onSelection={(value) => {
          formik.setFieldValue('currentSchoolName', value.name);
          formik.setFieldValue('currentSchoolCity', value.communeName);
        }}
        onTyping={setSchoolSearchValue}
        loading={debouncedLoading(schoolLoading)}
        emptyStateMessage={
          shouldShowSchoolEmptyStateMessage
            ? intl.formatMessage(
              ProfessorSituationTranslations.emptySchoolNameMessage
              )
            : ''
        }
        onEmptyStateClick={openCreateSchoolModal}
        disabled={formik.values.currentSchoolDepartment === ''}
      />

      <StaticAutocompleteMultiSelectInput
        dataCy="accompanied-study-levels"
        name="accompaniedStudyLevels"
        label={intl.formatMessage(
          ProfessorSituationTranslations.accompaniedStudyLevels,
        )}
        values={availableGrades.map((value) => ({
          value,
          translation: intl.formatMessage(GradeEnumTranslations[value]),
        }))}
        selectedValues={formik.values.accompaniedStudyLevels || []}
        onChange={(value: string) => addStudyLevelSupported(value as GradeEnum)}
        error={formatError(
          formik.touched,
          formik.errors,
          'accompaniedStudyLevels',
        )}
      />

      {formik.values.accompaniedStudyLevels.length > 0 && (
        <div className="coach-panel__tags">
          {formik.values.accompaniedStudyLevels.map((studyLevel) => (
            <RemovableTag
              key={studyLevel}
              value={studyLevel}
              name={studyLevel}
              translation={GradeEnumTranslations[studyLevel]}
              onRemove={() =>
                formik.setFieldValue(
                  'accompaniedStudyLevels',
                  formik.values.accompaniedStudyLevels.filter(
                    (v) => v !== studyLevel,
                  ),
                )
              }
            />
          ))}
        </div>
      )}

      <SelectInput
        dataCy="position"
        key={formik.values.secondarySituation}
        name="position"
        label={intl.formatMessage(
          ProfessorSituationTranslations.labelProfession,
        )}
        values={
          settingsContext?.settings?.jobTitles?.map(({ key, name }) => ({
            value: name,
            translation: name,
          })) ?? []
        }
        onChange={(value) => {
          if (value === JobTitleKeyEnum.Other) {
            setCustomJobTitle('');
          } else {
            setCustomJobTitle(null);
            formik.setFieldValue('position', value);
          }
        }}
        error={formatError(formik.touched, formik.errors, 'position')}
      />

      {customJobTitle !== null && (
        <TextInput
          name="customJobTitle"
          label={intl.formatMessage(ProfessorSituationTranslations.labelOther)}
          value={customJobTitle}
          onChange={(e) => {
            setCustomJobTitle(e.target.value);
            formik.setFieldValue('position', e.target.value);
          }}
        />
      )}

      {formik.values.position ===
        getJobTitleNameByKey(JobTitleKeyEnum.Teacher) && (
        <>
          <AutocompleteAddableMultiSelectInput
            dataCy="teached-school-subjects"
            name="teachedSchoolSubjects"
            label={intl.formatMessage(
              ProfessorSituationTranslations.labelTeachedSchoolSubjects,
            )}
            values={schoolSubjectData?.searchSchoolSubjects || []}
            selectedValues={formik.values.teachedSchoolSubjects}
            onSelection={addTeachedSchoolSubjects}
            onTyping={setSchoolSubjectValues}
            loading={debouncedSchoolSubjectLoading(schoolSubjectLoading)}
            error={formatError(
              formik.touched,
              formik.errors,
              'teachedSchoolSubjects',
            )}
          />

          <div className="coach-panel__tags">
            {formik.values.teachedSchoolSubjects.map((subject) => (
              <RemovableTag
                key={subject}
                value={subject}
                name={subject}
                onRemove={() =>
                  formik.setFieldValue(
                    'teachedSchoolSubjects',
                    formik.values.teachedSchoolSubjects.filter(
                      (v) => v !== subject,
                    ),
                  )
                }
              />
            ))}
          </div>
          {user?.primarySituation?.key ===
            PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport && (
            <RadioInput
              name="headTeacher"
              values={Object.values(BinaryResponse).map((resp) => ({
                value: resp,
                translation: intl.formatMessage(
                  BinaryResponseTranslations[resp],
                ),
              }))}
              initialSelectedIndex={user?.headTeacher === true ? 0 : 1}
              onSelection={(value) =>
                formik.setFieldValue('headTeacher', value)
              }
              label={intl.formatMessage(
                ProfessorSituationTranslations.labelPrincipalTeacher,
              )}
              inline={true}
              error={formatError(formik.touched, formik.errors, 'headTeacher')}
            />
          )}
        </>
      )}

      <PrimaryButton
        label={formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.dirty}
      />
    </form>
  );
}
