import { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import BriefcaseIcon from 'assets/component-icons/BriefcaseIcon';
import BuildingIcon from 'assets/component-icons/BuildingIcon';
import ClockRewindIcon from 'assets/component-icons/ClockRewindIcon';
import TextInput from '../../../components/text-input/TextInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';
import { ProfessionalSituationPanelTranslations } from './panels.translations';
import { professionalSituationPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import SaveIcon from 'assets/component-icons/SaveIcon';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import formikError from 'components/formik-helper/formik.helper';
import { PrimarySituationEnum } from '../../../@types/graphql';
import './ProfessionalSituationPanel.scss';

type FormikValues = {
  primarySituation: PrimarySituationEnum;
  position: string;
  companyName: string;
  yearsOfExperience: number;
};

export default function ProfessionalSituationPanel({
  primarySituation,
}: {
  primarySituation: PrimarySituationEnum;
}) {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();
  const initialPrimarySituationRef = useRef(primarySituation);

  const [initialValues, setInitialValues] = useState<FormikValues>({
    primarySituation: primarySituation,
    position: user?.jobTitle || '',
    companyName: user?.companyName || '',
    yearsOfExperience: user?.yearsOfExperience || 0,
  });

  const isPremium = user?.isPaidAmbassador;

  useEffect(() => {
    if (primarySituation !== initialPrimarySituationRef.current) {
      setInitialValues({
        primarySituation: primarySituation,
        position: '',
        companyName: '',
        yearsOfExperience: 0,
      });
    }
  }, [primarySituation]);

  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    validationSchema: professionalSituationPanelValidationSchema(intl),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      networkState.loading();
      const response = await updateUser(values);
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <>
      <TextInput
        name="position"
        label={intl.formatMessage(ProfessionalSituationPanelTranslations.job)}
        placeholder={intl.formatMessage(
          ProfessionalSituationPanelTranslations.jobPlaceholder,
        )}
        icon={BriefcaseIcon}
        value={formik.values.position}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'position')}
      />
      <div>
        <TextInput
          name="companyName"
          label={intl.formatMessage(
            ProfessionalSituationPanelTranslations.companyName,
          )}
          placeholder={intl.formatMessage(
            ProfessionalSituationPanelTranslations.companyNamePlaceholder,
          )}
          icon={BuildingIcon}
          value={formik.values.companyName}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'companyName')}
          disabled={isPremium}
        />
        {!isPremium && 
          <p className="company-change-warning">
            {intl.formatMessage(
              ProfessionalSituationPanelTranslations.companyNameWarning,
            )}
          </p>
        }
      </div>

      <TextInput
        name="yearsOfExperience"
        label={intl.formatMessage(
          ProfessionalSituationPanelTranslations.yearsOfExperience,
        )}
        icon={ClockRewindIcon}
        value={formik.values.yearsOfExperience}
        type="number"
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'yearsOfExperience')}
      />

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={SaveIcon}
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.dirty}
          onClick={() => formik.submitForm()}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </>
  );
}
