import { useMemo } from 'react';
import './StrengthProgressBar.scss';
import CheckIcon from 'assets/component-icons/CheckIcon';
import classNames from 'classnames';
import { computePasswordStrengthPercentage } from '../strength-hints/StrengthHints.util';

type StrengthProgressBarProps = {
  password: string;
};

export default function StrengthProgressBar({
  password,
}: StrengthProgressBarProps) {
  const progress = useMemo(
    () => computePasswordStrengthPercentage(password),
    [password],
  );

  return (
    <div className="strength-progress-bar">
      {progress === 100 ? (
        <CheckIcon className="strength-progress-bar__icon" />
      ) : null}
      <div className="strength-progress-bar__progress">
        <div
          className={classNames(
            'strength-progress-bar__progress__inner',
            {
              'strength-progress-bar__progress__inner--medium': progress >= 40,
            },
            {
              'strength-progress-bar__progress__inner--strong':
                progress === 100,
            },
          )}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
}
