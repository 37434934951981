import { useEffect } from 'react';
import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import CheckboxInput from '../../../components/checkbox-input/CheckboxInput';
import { MeetingPreference } from '../../../@types/graphql';
import { useIntl } from 'react-intl';
import { CommonTranslations } from 'i18n/common.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { meetingPreferencesPanelValidationSchema } from './panels.validation';
import { MeetingPreferencesPanelTranslations } from './panels.translations';

type FormikFields = {
  meetingPreferences: MeetingPreference[];
  acceptGroupConversations: boolean;
};

export default function MeetingPreferencesPanel() {
  const intl = useIntl();

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikFields>({
    initialValues: {
      meetingPreferences: [],
      acceptGroupConversations: false,
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: meetingPreferencesPanelValidationSchema(intl),
    onSubmit: async ({ meetingPreferences, acceptGroupConversations }) => {
      networkState.loading();
      if (meetingPreferences) {
        await updateUser({
          meetingPreferences: meetingPreferences.map(({ key, value }) => ({
            key,
            accept: value || false,
          })),
          acceptGroupConversations,
        });
      }
      networkState.succeeded();
    },
  });

  useEffect(() => {
    if (user?.meetingPreferences) {
      formik.setFieldValue('meetingPreferences', user.meetingPreferences);
    }
  }, [user?.meetingPreferences]);

  const handleCheckboxChange = (index: number, checked: boolean) => {
    formik.validateForm();
    formik.setFieldValue(`meetingPreferences[${index}].value`, checked);
  };

  return (
    <form>
      {formik.values.meetingPreferences?.map(({ key, name, value }, i) => (
        <CheckboxInput
          key={key}
          name={key}
          label={name}
          checked={value || false}
          onChange={(v) => handleCheckboxChange(i, v.target.checked)}
        />
      ))}

      <CheckboxInput
        name={intl.formatMessage(
          MeetingPreferencesPanelTranslations.acceptGroupConversation,
        )}
        label={intl.formatMessage(
          MeetingPreferencesPanelTranslations.acceptGroupConversation,
        )}
        checked={formik.values.acceptGroupConversations || false}
        onChange={(v) =>
          formik.setFieldValue('acceptGroupConversations', v.target.checked)
        }
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.isValid}
      />
    </form>
  );
}
